import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MODULES } from "../../utils/routesNames";
import ScrollAnimation from "react-animate-on-scroll";

export default function About() {
  return (
    <div>
      {/* Start About Block */}
      <section className="content-block about-block">
        <div className="container-xl text-center">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2 className="content-heading">About ARZ Food Service</h2>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={200}
              >
                <p>
                  ARZ is a family owned and operated business that has been
                  serving the Melbourne area for over 40 years. Our reputation
                  in the marketplace is defined as price fighters with an
                  exceptional grade of quality products.
                </p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={400}
              >
                <p>
                  ARZ are leaders in the food service industry. We are
                  well-established wholesale suppliers of food, grocery,
                  restaurant, and catering supplies. We provide distribution and
                  refrigerated transport for an extensive range of small goods,
                  dairy, dry, frozen, packaging and chemical products.
                </p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={800}
              >
                <Button as={Link} to={MODULES.aboutUs.url} variant="primary">
                  Read more
                </Button>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      {/* End About Block */}
    </div>
  );
}
