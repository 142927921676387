import { createContext } from "react";
import { UserDetails } from "../modals/User";
import { CartProducts } from "../modals/Cart";

export interface AppContextInterface {
  auth: UserDetails | null;
  setAuth: (value: UserDetails | null) => void;
  cart: CartProducts[];
  setCart: (value: CartProducts[] | []) => void;
  tokenExpired: () => void
}

const defaultContext: AppContextInterface = {
  auth: null,
  cart: [],
  setAuth: () => {},
  setCart: () => {},
  tokenExpired: () => {}
};

const AppContext = createContext(defaultContext);

export default AppContext;