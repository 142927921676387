import React, { useState, useContext } from "react";
import { QuestionDetails, Question } from "../../modals/Question";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/esm/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
// services
import { AskQuestionService } from "../../services/services";
// utils
import {
  regEx,
  askQuestionMessages as MSG,
  generalMessages,
} from "../../utils/constants";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";

interface ParentProps {
  closeModal: Function;
  productId: number;
  question: Question;
}

const QuestionSchema = Yup.object().shape({
  UserID: Yup.number(),
  ProductID: Yup.number(),
  QuestionID: Yup.number(),
  Name: Yup.string().required(MSG.NAME_REQUIRED),
  MobileNumber: Yup.string()
    .matches(regEx.mobileNumberValidation, MSG.INVALID_MOBILE)
    .required(MSG.MOBILE_REQUIRED),
  // CompanyName: Yup.string().required(MSG.COMPANY_REQUIRED),
  Comments: Yup.string().required(MSG.COMMENT_REQUIERD),
});

const AskQuestionForm: React.FC<ParentProps> = ({
  productId,
  question: { id, question },
  closeModal,
}) => {
  const { auth, tokenExpired } = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const [questionState] = useState<QuestionDetails>({
    UserID: auth?.id,
    ProductID: productId,
    QuestionID: id,
    Name: auth ? auth.name : "",
    MobileNumber: auth?.mobile_number,
    // CompanyName: auth?.company_name ? auth.company_name : "",
    Comments: "",
  });

  const handleFormSubmit = (values: QuestionDetails, bag: any) => {
    setError(null);
    AskQuestionService(values)
      .then((result) => {
        if (result.data.flag) {
          toast.success(generalMessages.QUESTION_SUBMITTED);
        } else {
          toast.error(generalMessages.ERROR_MSG);
        }
        bag.setSubmitting(false);
        closeModal();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const addFocusClass = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.parentElement?.classList.add("has-focused");
  };

  const removeFocusClass = ({
    e,
    setFieldTouched,
  }: {
    e: React.FocusEvent<HTMLInputElement>,
    setFieldTouched:any,
  }) => {
    var inputValue = e.target.value;
    setFieldTouched(e.target.name, true, true);
    e.target.parentElement?.classList.remove("has-focused");
    if (inputValue === "") {
      e.target.parentElement?.classList.remove("has-value");
    } else {
      e.target.parentElement?.classList.add("has-value");
    }
  };

  return (
    <div className="selected-question-wrapper">
      <p className="selected-question">{question}</p>
      {error && <span>{error}</span>}
      <Formik
        validationSchema={QuestionSchema}
        initialValues={questionState}
        onSubmit={(values, bag) => handleFormSubmit(values, bag)}
        validateOnBlur
        validateOnChange
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldTouched, isSubmitting }) => (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <Form.Group
              className={!values.Name ? "floatLabel" : "floatLabel has-value"}
            >
              <Form.Label className="control-label">Full Name</Form.Label>
              <Field
                name="Name"
                type="text"
                className="form-control"
                onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                  addFocusClass(e)
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  removeFocusClass({ e, setFieldTouched })
                }
              />
              <span className="invalid-control">
                <ErrorMessage name="Name" />
              </span>
            </Form.Group>
            {/* <Form.Group
              className={
                !values.CompanyName ? "floatLabel" : "floatLabel has-value"
              }
            >
              <Form.Label className="control-label">Company</Form.Label>
              <Field
                name="CompanyName"
                type="text"
                className="form-control"
                onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                  addFocusClass(e)
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  removeFocusClass({ e, setFieldTouched })
                }
              />
              <span className="invalid-control">
                <ErrorMessage name="CompanyName" />
              </span>
            </Form.Group> */}
            <Form.Group
              className={
                !values.MobileNumber ? "floatLabel" : "floatLabel has-value"
              }
            >
              <Form.Label className="control-label">Mobile Number</Form.Label>
              <Field
                name="MobileNumber"
                type="text"
                className="form-control"
                onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                  addFocusClass(e)
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  removeFocusClass({ e, setFieldTouched })
                }
              />
              <span className="invalid-control">
                <ErrorMessage name="MobileNumber" />
              </span>
            </Form.Group>
            <Form.Group
              className={
                !values.Comments ? "floatLabel" : "floatLabel has-value"
              }
            >
              <Form.Label className="control-label">Message</Form.Label>
              <Field
                name="Comments"
                type="text"
                className="form-control"
                onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                  addFocusClass(e)
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  removeFocusClass({ e, setFieldTouched })
                }
              />
              <span className="invalid-control">
                <ErrorMessage name="Comments" />
              </span>
            </Form.Group>

            <Button
              type="submit"
              block
              variant="accent"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "submit"
              )}
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default AskQuestionForm;
