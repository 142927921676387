import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import { ProductDetails } from "../../modals/Product";
import { history } from "../../utils/appConfig";
import { MODULES } from "../../utils/routesNames";
import { isValidImageUrl } from "../../utils/common";
import { NO_IMG } from "../../assets/images/images";
import ListingWidget from "../AddToCartForm/ListingWidget";
import SubstituteProducts from "../SubstituteProducts";

const ProductItem: React.FC<ProductDetails> = ({
  name,
  price,
  product_image,
  id,
  category_id,
  out_of_stock,
}) => {
  const [image, setImage] = useState(product_image);

  return (
    <div className="product-list-item">
      <i className="product-list-item-img">
        <img
          onError={() => setImage(NO_IMG)}
          src={isValidImageUrl(image) ? image : NO_IMG}
          alt={product_image}
        />
      </i>
      <div className="product-list-item-detail">
        <div>
          <div
            className="label-action"
            onClick={() =>
              history.push(
                `${MODULES.allProducts.url}${MODULES.productDetails.url}/${id}`
              )
            }
          >
            <span>{name}</span>
          </div>
          <span>$ {price}</span>
          {out_of_stock ? (
            <div className="out-of-stock">
              {out_of_stock ? "OUT OF STOCK" : ""}
              <SubstituteProducts  id={id}/>
            </div>
          ) : (
            ""
          )}
        </div>
        <Form.Group className="ml-md-auto form-group">
          <ListingWidget
            id={id}
            is_saved={0}
            isListing
            out_of_stock={out_of_stock}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default ProductItem;
