import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import Form from "../../../node_modules/react-bootstrap/Form";

import * as Yup from "yup";
import {
  myAccountMessages as MSG,
  regEx,
  addressTypeOptions,
  companyTypeOptions,
  generalMessages,
} from "../../utils/constants";
import { Account, UserDetails } from "../../modals/User";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import {
  SaveAccountSettingsService,
  getAccountSettingsService,
} from "../../services/services";
import Spinner from "react-bootstrap/esm/Spinner";
import AppContext from "../../context/AppContext";
import { setLocalStorage } from "../../utils/common";
import { toast } from "react-toastify";

const accountSchema = Yup.object({
  first_name: Yup.string().required(MSG.FIRST_NAME_REQUIERD),
  last_name: Yup.string().required(MSG.LAST_NAME_REQUIERD),
  email: Yup.string().email(MSG.INVALID_EMAIL).required(MSG.EMAIL_REQUIRED),
  mobile_number: Yup.string()
    .matches(regEx.mobileNumberValidation, MSG.INVALID_MOBILE)
    .required(MSG.MOBILE_REQUIRED),
  public_note: Yup.string().required(MSG.PUBLIC_NOTE_REQUIRED).nullable(),
  address_type: Yup.string().required(MSG.ADDRESS_TYPE_REQUIRED),
  trading_name: Yup.string().required(MSG.TRADING_NAME_REQUIRED),
  company_type: Yup.string().required(MSG.COMPANY_TYPE_REQUIRED).nullable(),
  business_name: Yup.string().required(MSG.BUSINESS_NAME_REQUIRED).nullable(),
  telephone_number: Yup.string()
    .matches(regEx.mobileNumberValidation, MSG.INVALID_TELEPHONE)
    .required(MSG.TELEPHONE_REQUIRED),
  fax: Yup.string()
    .matches(regEx.faxNumber, MSG.INVALID_FAX)
    .required(MSG.FAX_REQUIRED),
  abn_number: Yup.string().required(MSG.ABN_REQUIRED),
});

const AccountSettings = () => {
  const [myAccountState, setmyAccountState] = useState<Account>({
    address_type: "",
    business_name: "",
    company_type: "",
    email: "",
    fax: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    public_note: "",
    telephone_number: "",
    trading_name: "",
    abn_number: "",
  });

  const [abnOpen, setAbnOpen] = useState(false);
  const [olderABN, setOlderABN] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAuth, tokenExpired } = useContext(AppContext);

  useEffect(() => {
    getAccountDetails();
  }, []); // eslint-disable-line

  const getAccountDetails = () => {
    getAccountSettingsService()
      .then((result) => {
        if (result.data.flag) {
          const data = result.data.data;
          const nameArray: [] = data?.user?.name
            ? data.user.name.split(" ")
            : [];
          const userData = {
            address_type: data?.company?.address_type
              ? data.company.address_type
              : "",
            abn_number: data?.company?.abn_number
              ? data.company.abn_number
              : "",
            business_name: data?.company?.business_name
              ? data.company.business_name
              : "",
            company_type: data?.company?.company_type
              ? data.company.company_type
              : "",
            fax: data?.company?.fax ? data.company.fax : "",
            first_name:
              nameArray.length > 0
                ? nameArray.slice(0, nameArray.length - 1).join(" ")
                : "",
            last_name:
              nameArray.length > 0 ? nameArray[nameArray.length - 1] : "",
            trading_name: data?.company?.trading_name
              ? data.company.trading_name
              : "",
            email: data?.user?.email ? data.user?.email : "",
            mobile_number: data?.user?.mobile_number
              ? data.user.mobile_number
              : "",
            company_name: data?.user?.company_name
              ? data.user.company_name
              : "",
            public_note: data?.user?.public_note ? data.user.public_note : "",
            telephone_number: data?.company?.telephone_number
              ? data.company.telephone_number
              : "",
          };
          setmyAccountState(userData);
          setOlderABN(data?.company?.abn_number);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const saveAccountDetails = (values: Account) => {
    values.first_name = values.first_name + " " + values.last_name;
    SaveAccountSettingsService(values)
      .then((result) => {
        if (result.data.flag) {
          toast.success(result.data.message);
        } else {
          toast.error(generalMessages.ERROR_MSG);
        }
        setAbnOpen(false);
        return getAccountSettingsService();
      })
      .then((result) => {
        if (result.data.flag) {
          const data = result.data.data;
          const newUserDetails: UserDetails = {
            company_name: data?.user?.company_name
              ? data.user.company_name
              : "",
            id: data?.user?.id,
            mobile_number: data?.user?.mobile_number
              ? data.user.mobile_number
              : "",
            name: data?.user?.name ? data.user.name : "",
            trading_name: data?.company?.trading_name
              ? data.company.trading_name
              : "",
            user_name: data?.user?.user_name ? data.user.user_name : "",
          };
          setAuth(newUserDetails);
          setLocalStorage("user", newUserDetails);
          getAccountDetails();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const updateABNNumber = (value: string) => {
    if (!abnOpen) {
      setAbnOpen(true);
      return;
    }
    const abnPayload = { abn_number: value };
    setLoading(true);
    SaveAccountSettingsService(abnPayload)
      .then((result) => {
        if (result.data.flag) {
          setAbnOpen(false);
          toast.success(result.data.message);
        } else {
          toast.error(generalMessages.ERROR_MSG);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        <section className="page-block account-setting">
          <div className="container-xl mb-xl-5">
            <h3 className="page-title">account settings</h3>
            <Formik
              enableReinitialize
              validateOnChange
              validateOnBlur
              validationSchema={accountSchema}
              initialValues={myAccountState}
              onSubmit={(values) => saveAccountDetails(values)}
            >
              {({
                handleSubmit,
                values,
                errors,
                validateField,
                setFieldValue,
                isSubmitting,
              }) => (
                <FormikForm noValidate onSubmit={handleSubmit}>
                  <div className="information-block">
                    <h4 className="section-heading">Personal Information</h4>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>First Name</Form.Label>
                          <Field
                            name="first_name"
                            type="text"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="first_name" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>Last Name</Form.Label>
                          <Field
                            type="text"
                            name="last_name"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="last_name" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>Email</Form.Label>
                          <Field
                            type="text"
                            name="email"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="email" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>Mobile Number</Form.Label>
                          <Field
                            name="mobile_number"
                            type="text"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="mobile_number" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label>Public Note</Form.Label>
                          <Field
                            as="textarea"
                            name="public_note"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="public_note" />
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="information-block">
                    <h4 className="section-heading">Company Information</h4>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Group>
                          <Form.Label>Address Type</Form.Label>
                          <Field
                            component="select"
                            name="address_type"
                            className="form-control"
                          >
                            {addressTypeOptions.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Field>
                          <span className="errorMessage">
                            <ErrorMessage name="address_type" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>Trading Name (Shop Name)</Form.Label>
                          <Field
                            readOnly
                            name="trading_name"
                            type="text"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="trading_name" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <Form.Group>
                          <Form.Label>Company Type</Form.Label>
                          <Field
                            component="select"
                            name="company_type"
                            className="form-control"
                          >
                            {companyTypeOptions.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Field>
                          <span className="errorMessage">
                            <ErrorMessage name="company_type" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label>Business Name</Form.Label>
                          <Field
                            name="business_name"
                            type="text"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="business_name" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <div className="editable-label">
                          ABN :
                          {abnOpen ? (
                            <Form.Group className="form-group">
                              <Field
                                autoFocus={abnOpen}
                                name="abn_number"
                                type="text"
                                className="form-control"
                                onFocus={() =>
                                  setOlderABN(values.abn_number.toString())
                                }
                              />
                              <span className="errorMessage">
                                <ErrorMessage name="abn_number" />
                              </span>
                            </Form.Group>
                          ) : (
                            <span className="mr-2 ml-2">
                              {values.abn_number
                                ? values.abn_number
                                : "Not Available"}
                            </span>
                          )}
                          <Button
                            onClick={() => {
                              if (errors.abn_number) {
                                validateField("abn_number");
                                return;
                              }
                              updateABNNumber(values.abn_number.toString());
                            }}
                            className="btn btn-strock"
                          >
                            {abnOpen ? (
                              loading ? (
                                <Spinner animation="border" variant="dark" />
                              ) : (
                                "Update ABN"
                              )
                            ) : (
                              "Edit ABN"
                            )}
                          </Button>
                          {abnOpen && (
                            <Button
                              onClick={() => {
                                setFieldValue("abn_number", olderABN);
                                setAbnOpen(false);
                              }}
                              className="btn btn-strock ml-2"
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>Telephone Number</Form.Label>
                          <Field
                            name="telephone_number"
                            type="text"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="telephone_number" />
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group className="form-group">
                          <Form.Label>FAX</Form.Label>
                          <Field
                            name="fax"
                            type="text"
                            className="form-control"
                          />
                          <span className="errorMessage">
                            <ErrorMessage name="fax" />
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <Button
                      className="mr-3"
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AccountSettings;
