import React, { useState, useEffect } from "react";
import logo from "./assets/images/logo.svg";
import "./App.css";
import Routes from "./routes";
import "./assets/css/common.css";
import "./assets/css/font.css";
import "./assets/css/style.css";
import AppContext from "./context/AppContext";
import { UserDetails } from "./modals/User";
import { CartProducts } from "./modals/Cart";
import { ViewCartService } from "./services/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastProps, generalMessages } from "./utils/constants";

const App = () => {
  const [auth, setAuth] = useState<UserDetails | null>(null);
  const [cart, setCart] = useState<CartProducts[]>([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const userDetails = localStorage.getItem("user");
      if (userDetails) {
        setAuth(JSON.parse(userDetails));
        getCart();
      }
    }
  }, []); // eslint-disable-line

  const getCart = () => {
    ViewCartService()
      .then((result) => {
        if (result) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const tokenExpired = () => {
    localStorage.clear();
    setAuth(null);
    setCart([]);
    toast.info(generalMessages.RE_LOGIN_REQUIRED);
  };

  return (
    <div className="App">
      <ToastContainer limit={3} autoClose={toastProps.delay} hideProgressBar />
      <title className="App-title">
        <img src={logo} className="App-logo" alt="logo" />
      </title>
      <AppContext.Provider
        value={{ auth, setAuth, cart, setCart, tokenExpired }}
      >
        <Routes />
      </AppContext.Provider>
    </div>
  );
};

export default App;
