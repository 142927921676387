import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import { OrderHistoryDetails } from "../../modals/Order";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import {
  OrderHistoryService,
  DuplicateOrder,
  ViewCartService,
} from "../../services/services";
import { MODULES } from "../../utils/routesNames";
import { history } from "../../utils/appConfig";
import {
  generalMessages as MSG,
  orderDetailMessages as orderDetailMSG,
} from "../../utils/constants";
import AppContext from "../../context/AppContext";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const OrderHistory: React.FC = () => {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  const { setCart, tokenExpired } = useContext(AppContext);
  const [orderList, setOrderList] = useState<OrderHistoryDetails[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    const getOrderList = () => {
      setLoading(true);
      OrderHistoryService()
        .then((result) => {
          if (result.data.flag) {
            setOrderList(result.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            tokenExpired();
          } else {
            setStatus(MSG.ERROR_MSG);
          }
        });
    };
    getOrderList();
  }, []); // eslint-disable-line

  const duplicateOrder = (orderId: number) => {
    DuplicateOrder(orderId)
      .then((result) => {
        if (result.data.flag) {
          toast.success(orderDetailMSG.SUCCESS);
        } else {
          toast.error(result.data.message);
        }
        return ViewCartService();
      })
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        } else {
          toast.error(MSG.ERROR_MSG);
        }
      });
  };

  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        <section className="page-block">
          <div className="container-xl">
            <h3 className="page-title">My Orders</h3>
            <div className="my-order-wrapper">
              {loading ? (
                <Loader />
              ) : status ? (
                status
              ) : orderList.length > 0 ? (
                orderList.map((orderItem) => (
                  <div className="my-order-card" key={orderItem.id}>
                    <div>
                      <div className="labeled-data">
                        <p>Order Date</p>
                        <span>{orderItem.order_date}</span>
                      </div>
                      <div className="labeled-data">
                        <p>Order Amount</p>
                        <span>${orderItem.sub_total}</span>
                      </div>
                      <Button
                        onClick={() =>
                          history.push(
                            `${MODULES.orderDetails.url}/${orderItem.id}`
                          )
                        }
                        variant="primary"
                      >
                        View Order
                      </Button>
                      <Button
                        className="btn btn-strock"
                        onClick={() => duplicateOrder(orderItem.id)}
                      >
                        Duplicate Order
                      </Button>
                    </div>
                    <p className="orderID">#{orderItem.id}</p>
                  </div>
                ))
              ) : (
                MSG.NO_ORDERS
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default OrderHistory;
