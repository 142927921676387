import React, { useState, useEffect, useContext } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { Product, UpdateProduct } from "../../../modals/Product";
import {
    SavedProductService,
    UpdateSavedProductService,
    removeItemFromSavedProductService,
} from "../../../services/services";
import {
    generalMessages,
    savedProductTypes,
    productFilters,
} from "../../../utils/constants";
import Loader from "../../Loader";
import SavedProduct from "./savedProduct";
import SavedProductDetail from "./savedProductDetail";
import AppContext from "../../../context/AppContext";
import SearchIcon from "../../../assets/images/Search_Icon.png";

const SavedProducts = () => {
    const { tokenExpired } = useContext(AppContext);
    const [products, setProducts] = useState<Product[]>([]);
    const [selectedProductFilter, setSelectedProductFilter] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    // const [input, setInput] = useState("");
    const [filterproducts, setfilterProducts] = useState<Product[]>([]);

    useEffect(() => {
        getUserSavedProducts(1);
    }, []); // eslint-disable-line

    const getUserSavedProducts = async (flag: number) => {
        setStatus(null);
        setLoading(true);
        setProducts([]);
        setSelectedProductFilter(flag);
        SavedProductService(flag)
            .then((result) => {
                if (result.data.flag) {
                    setProducts(result.data.data);
                    setfilterProducts(result.data.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.status === 401) {
                    tokenExpired();
                } else {
                    setStatus(generalMessages.ERROR_MSG);
                }
            });
    };

    const filterProducts = (value: any) => {
        switch (value) {
            case productFilters.ASCENDING:
                setProducts([
                    ...products.sort((p1, p2) =>
                        p1.product_name.localeCompare(p2.product_name)
                    ),
                ]);
                break;
            case productFilters.DECESENDING:
                setProducts([
                    ...products
                        .sort((p1, p2) =>
                            p1.product_name.localeCompare(p2.product_name)
                        )
                        .reverse(),
                ]);
                break;
            case productFilters.NEWEST:
                setProducts([
                    ...products
                        .sort(
                            (p1, p2) =>
                                +new Date(p1.created_at) -
                                +new Date(p2.created_at)
                        )
                        .reverse(),
                ]);
                break;
            case productFilters.HIGH_TO_LOW:
                setProducts([
                    ...products.sort(
                        (p1, p2) => p2.product_price - p1.product_price
                    ),
                ]);
                break;
            case productFilters.LOW_TO_HIGH:
                setProducts([
                    ...products
                        .sort((p1, p2) => p2.product_price - p1.product_price)
                        .reverse(),
                ]);
                break;
            default:
                break;
        }
    };

    const handleMoveProduct = (id: number) => {
        const product = products.find((product) => product.id === id);
        if (product) {
            const newType = savedProductTypes.find(
                (t) => t.type !== product.type
            )?.value;
            const payload: UpdateProduct = {
                product_id: product.product_id,
                type: newType,
            };
            UpdateSavedProductService(id, payload)
                .then((result) => {
                    if (result.data.flag) {
                        const filtered = products.filter(
                            (product) => product.id !== id
                        );
                        setProducts(filtered);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        tokenExpired();
                    }
                });
        }
    };

    const handleDeleteProduct = (id: number) => {
        removeItemFromSavedProductService(id)
            .then((result) => {
                if (result.data.flag) {
                    const filtered = products.filter(
                        (product) => product.id !== id
                    );
                    setProducts(filtered);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    tokenExpired();
                }
            });
    };

    /////////////////search feature

    const handleSearch = (keyword) => {
        const filtered = products.filter((p1) => {
            return p1.product_name
                .toLowerCase()
                .includes(keyword.toLowerCase());
        });
        setfilterProducts(filtered);
    };

    return selectedProduct ? (
        <SavedProductDetail
            id={selectedProduct}
            backToList={() => setSelectedProduct(null)}
        />
    ) : (
        <div className="product-wrapper  container-xl">
            <div
                className="form-group"
                style={{
                    position: "relative",
                    width: "-webkit-fill-available",
                }}
            >
                <input
                    style={{
                        width: "-webkit-fill-available",
                        // background: "#f4f4f4",
                        marginBottom: "20px",
                    }}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder={"Search Saved Product"}
                    className="form-control"
                />

                <img
                    src={SearchIcon}
                    alt=""
                    style={{
                        position: "absolute",
                        right: 18,
                        bottom: 10,
                        height: 30,
                    }}
                />
            </div>

            {/* Loop Start */}
            <section className="product-details">
                <div className="container-xl">
                    <div className="sorting-block">
                        <Form.Group>
                            <Form.Control
                                onChange={(e) =>
                                    getUserSavedProducts(+e.target.value)
                                }
                                as="select"
                                value={selectedProductFilter}
                            >
                                {savedProductTypes.map((type, index) => (
                                    <option key={index} value={type.value}>
                                        {type.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <DropdownButton
                            alignRight
                            title="Sort"
                            variant="accent"
                            className="sort-menu default-menu"
                            onSelect={filterProducts}
                        >
                            {Object.keys(productFilters).map((type, index) => (
                                <Dropdown.Item
                                    key={index}
                                    eventKey={productFilters[type]}
                                >
                                    {productFilters[type]}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                    {/* Sorting Block End */}

                    {/* saved product loop */}
                    <div className="product-list-item-wrapper">
                        {loading ? (
                            <Loader />
                        ) : status ? (
                            status
                        ) : filterproducts.length > 0 ? (
                            filterproducts.map((product, index) => (
                                <SavedProduct
                                    key={index}
                                    {...product}
                                    deleteItem={() =>
                                        handleDeleteProduct(product.id)
                                    }
                                    moveItem={() =>
                                        handleMoveProduct(product.id)
                                    }
                                    loadItem={() =>
                                        setSelectedProduct(product.product_id)
                                    }
                                />
                            ))
                        ) : (
                            generalMessages.EMPTY_PRODUCTS
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SavedProducts;
