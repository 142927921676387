import axiosInstance from "./api";
import { SignUpDetails } from "../modals/SignUp";
import { LoginDetails } from "../modals/Login";
import { QuestionDetails } from "../modals/Question";
import { AddToCart } from "../modals/Cart";
import { OrderCheckout } from "../modals/OrderCheckout";
import { GeneralInquiry } from "../modals/GeneralInquiry";
import { UpdateProduct } from "../modals/Product";
import { Account } from "../modals/User";

//login Service
export const LoginService = (credentials: LoginDetails) => {
  const loginPayload = {
    user_name: `${credentials.UserName}`,
    password: `${credentials.Password}`,
  };
  return axiosInstance.post("/authentication/login", loginPayload);
};

//Registration Service
export const SignUpService = (signUpData: SignUpDetails) => {
  const signUpPayload = {
    first_name: `${signUpData.FirstName}`,
    mobile_number: `${signUpData.MobileNumber}`,
    email: `${signUpData.EmailID}`,
    trading_name: `${signUpData.TradingName}`,
  };
  return axiosInstance.post("/authentication/register", signUpPayload);
};

//Category Service
export const CategoryService = () => {
  return axiosInstance.get("/customer/categories");
};

//Fetch Saved Product Service
export const SavedProductService = (flag: number) => {
  return axiosInstance.get("/customer/save-product/list/" + flag);
};

// update saved product (Move to commonly/occasionally)
export const UpdateSavedProductService = (
  id: number,
  payload: UpdateProduct
) => {
  return axiosInstance.put(`/customer/save-product/${id}`, payload);
};

// delete item from saved products list
export const removeItemFromSavedProductService = (id: number) => {
  return axiosInstance.delete(`/customer/save-product/${id}`);
};

// History Products Service
export const HistoryProductService = () => {
  // TODO: /customer/history-product/list
  return axiosInstance.get("/customer/history-product/list");
};

// Get Question List Service
export const GetQuestionListService = () => {
  return axiosInstance.get("/customer/get-question");
};

//Ask Question Service
export const AskQuestionService = (askQuestionData: QuestionDetails) => {
  const askQuestPayload = {
    name: `${askQuestionData.Name}`,
    mobile_number: `${askQuestionData.MobileNumber}`,
    user_id: `${askQuestionData.UserID}`, // Needed?
    product_id: `${askQuestionData.ProductID}`, // Needed?
    question_id: `${askQuestionData.QuestionID}`,
    // company_name: `${askQuestionData.CompanyName}`,
    comments: `${askQuestionData.Comments}`,
  };
  return axiosInstance.post("/customer/ask-question", askQuestPayload);
};

// Product Details Service
export const GetProductDetailsService = (productID: string) => {
  return axiosInstance.get("/customer/product-detail/" + productID);
};

// Add To Cart Service
export const AddToCartService = (cartData: AddToCart) => {
  const addToCartPayload = {
    product_id: `${cartData.ProductID}`,
    quantity: `${cartData.Quantity}`,
    user_id: `${cartData.UserID}`, // Needed?
  };
  return axiosInstance.post("/customer/cart/add-product", addToCartPayload);
};

// view Cart Service
export const ViewCartService = () => {
  return axiosInstance.get("/customer/cart/get-products");
};

// Update Quantity of Product in Cart Service
export const UpdateCartService = (
  id: number,
  productId: number,
  quantity: number
) => {
  const updateCartPayload = {
    product_id: productId,
    quantity: quantity,
  };
  return axiosInstance.put(
    "/customer/cart/update-product/" + id,
    updateCartPayload
  );
};

// Remove Product from cart
export const RemoveProductFromCartService = (id: string) => {
  return axiosInstance.delete("/customer/cart/delete-product/" + id);
};

//Add Saved Product Service
export const AddSavedProductService = (
  productId: number,
  saveListType: string
) => {
  const addSavedProductPayload = {
    user_id: "2", // needed?
    product_id: productId,
    type: saveListType,
  };
  return axiosInstance.post("/customer/save-product", addSavedProductPayload);
};

// View Order History Service
export const OrderHistoryService = () => {
  return axiosInstance.get("/customer/orders");
};

// View History Order Full Details Service
export const HistoryOrderFullDetailService = (orderID: string) => {
  return axiosInstance.get("/customer/order-details/" + orderID);
};

// contact us service
export const GeneralInqiryService = (values: GeneralInquiry) => {
  return axiosInstance.post("/general-inquiry", values);
};

//View Product List by Category
export const ProductListByCategory = (categoryID: string) => {
  return axiosInstance.get("/customer/categories/" + categoryID + "/products");
};

// customer checkout add products
export const CustomerCheckout = (payload: OrderCheckout) =>
  axiosInstance.post("/customer/checkout/add-products", payload);

export const DuplicateOrder = (orderId: number) =>
  axiosInstance.post(`/customer/cart/repeat-order/${orderId}`, null);

export const getAccountSettingsService = () => {
  return axiosInstance.get("/customer/user-detail");
};

export const SaveAccountSettingsService = (values: Account | object) => {
  return axiosInstance.put("/customer/update-user", values);
};

export const SearchAllProducts = (searchQuery: object) => {
  return axiosInstance.post("/customer/products/search", searchQuery);
};

// Get Substitute Products
export const GetSubstituteProducts = (productId: string) => {
  return axiosInstance.get("/customer/oos-product-detail/" + productId);
};
