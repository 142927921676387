import React, { useState, useContext } from "react";
import Form from "../../../node_modules/react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/esm/Button";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { MODULES } from "../../utils/routesNames";
import { LoginDetails } from "../../modals/Login";
import { LoginService, ViewCartService } from "../../services/services";
import { setLocalStorage } from "../../utils/common";
import { history } from "../../utils/appConfig";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { generalMessages } from "../../utils/constants";
import { LOGIN_LOGO } from "../../assets/images/images";

const loginSchema = Yup.object({
  UserName: Yup.string().required("Username is required"),
  Password: Yup.string().required("Password is required"),
});

const Login: React.FC = () => {
  const { setAuth, setCart } = useContext(AppContext);
  const [loginState] = useState<LoginDetails>({
    UserName: "",
    Password: "",
  });

  const loginUser = async (credentials: LoginDetails) => {
    const result = await LoginService(credentials);
    if (result.data.flag) {
      setLocalStorage("token", result.data.data.token);
      setLocalStorage("user", result.data.data.user);
      setAuth(result.data.data.user);

      const res = await ViewCartService();
      if (res.data.flag) {
        setCart(res.data.data);
      }
      toast.success(generalMessages.LOGIN_SUCCESS);
      history.push(MODULES.savedProducts.url);
    } else {
      toast.error(result.data.message);
    }
  };

  return (
    <div>
      {/* form Wrapper Start */}
      <div className="form-wrapper-outer">
        <div className="form-wrapper">
          <div className="form-outer">
            {/* Form Start */}
            <div className="form-block">
              {/* form Header Start */}
              <div className="form-header">
                <Link
                  to={MODULES.home.url}
                  title="ARZ Food Service"
                  className="logo"
                >
                  <img src={LOGIN_LOGO} alt="logo" />
                </Link>
              </div>
              {/* form Header End */}

              {/* form Body Start */}
              <div className="form-body">
                <h2>Login</h2>
                <Formik
                  enableReinitialize
                  validateOnChange
                  validateOnBlur
                  validationSchema={loginSchema}
                  initialValues={loginState}
                  onSubmit={(values) => loginUser(values)}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <FormikForm noValidate onSubmit={handleSubmit}>
                      <Form.Group className="form-group">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          name="UserName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.UserName}
                          isInvalid={touched.UserName && !!errors.UserName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.UserName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          className="form-control"
                          name="Password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.Password}
                          isInvalid={touched.Password && !!errors.Password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.Password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Spinner animation="border" variant="light" />
                        ) : (
                          "Login"
                        )}
                      </Button>
                      <p className="register">
                        Don’t have an account ?{" "}
                        <Link
                          to={MODULES.signUp.url}
                          className="primary-link"
                          title="Register Account"
                        >
                          Register Account
                        </Link>
                      </p>
                    </FormikForm>
                  )}
                </Formik>
              </div>
              {/* form Body End */}

              <p className="form-footer">
                <Link
                  to={MODULES.home.url}
                  className="white-link"
                  title="Back to Homepage"
                >
                  Back to Homepage
                </Link>
              </p>
            </div>
            {/* Form End */}
          </div>
        </div>
      </div>
      {/* form Wrapper End */}
    </div>
  );
};

export default Login;
