import React, { useState, useContext, useEffect } from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import Form from "../../../node_modules/react-bootstrap/Form";
import Modal from "react-bootstrap/esm/Modal";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import { MODULES } from "../../utils/routesNames";
import DatePicker from "react-datepicker";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import momentTZ from 'moment-timezone';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import {
  CustomerCheckout,
  ViewCartService,
  RemoveProductFromCartService,
} from "../../services/services";
import { OrderCheckout } from "../../modals/OrderCheckout";
import { CartProducts } from "../../modals/Cart";
import {
  MODAL_CLOSE
} from "../../assets/images/images";
import { ADD_PRIMARY, EMPTY_CART, SUCCESSFULL_ORDER, BIN } from "../../assets/images/svgImage";
import {
  generalMessages as MSG,
  checkoutMessages,
} from "../../utils/constants";
import UpdatableField from "./updatableField";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";

const Cart: React.FC = () => {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  const { cart, setCart, tokenExpired } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    ViewCartService()
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  }, []); // eslint-disable-line

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const todayDate = new Date();

  const checkoutSchema = Yup.object({
    deliveryDate: Yup.date()
      .required(checkoutMessages.DATE_REQUIRED)
      .nullable(),
  });

  // delete item from cart.
  const removeItem = (item: CartProducts) => {
    RemoveProductFromCartService(item.id.toString())
      .then((result) => {
        if (result.data.flag) {
          toast.success(result.data.message);
        } else {
          toast.error(MSG.ERROR_MSG);
        }
        return ViewCartService();
      })
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const checkoutCustomer = (checkoutValues: {
    deliveryDate: Date | null;
    comments: string;
  }) => {


    const formattedData = formatValues(checkoutValues);

    CustomerCheckout(formattedData)
      .then((result) => {
        if (result.data.flag) {
          handleShow();
        }
        return ViewCartService();
      })
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const formatValues = (formikValues: {
    deliveryDate: Date | null;
    comments: string;
  }) => {
    const currentDateTime = momentTZ().tz("Australia/Melbourne").format("DD-MM-YYYY HH:mm:ss");
    const formattedValues: OrderCheckout = {
      orderDate: currentDateTime,
      expectedDeliveryDate: formikValues.deliveryDate && formattedDate(formikValues.deliveryDate) || "",
      comments: formikValues.comments,
      subTotal: Number(getSubTotal()),
      shipping: 50,
      grandTotal: Number(getSubTotal()), //removed shipping, can add if required
      products: cart.map((item) => {
        return {
          product_id: item.product_id,
          product_name: item.product_name,
          price: item.product_price,
          quantity: item.quantity,
          total_price: item.total_price,
        };
      }),
    };
    return formattedValues;
  };

  const getSubTotal = () =>
    cart
      .reduce(
        (sum, { product_price, quantity }) => sum + product_price * quantity,
        0
      )
      .toFixed(2);

  const getTotal = () =>
    cart
      .reduce(
        (sum, { total_price }) => sum + total_price,
        0
      )
      .toFixed(2);

  // const getTax = () =>
  //   cart
  //     .reduce(
  //       (sum, { product_price, quantity, total_price }) => sum + (total_price - product_price * quantity),
  //       0
  //     )
  //     .toFixed(2);

  // format date to 'dd-mm-yyyy format'
  const formattedDate = (date: Date) => {
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}-${month}-${year}`;
  }
  const is_weekend = (date1) => {
    var dt = new Date(date1);

    if (dt.getDay() === 6 || dt.getDay() === 0) {
      return true;
    }
    return false;
  }

  const message = () => {
    let today = moment(),
      dayOfWeek = today.day(),
      msg =
        "Thank you, your order has been submitted and will be dispatched by your supplier soon, for exact delivery timeframe please contact them directly. Please check your email for order details and a to link to this order's invoice.\n\n";

    let isWorkingDay = dayOfWeek !== 0 && dayOfWeek !== 6;
    let isWorkingHours = false;
    if (isWorkingDay) {
      isWorkingHours = today.isBefore(moment('4:30pm', 'hh:mma'));
      if (!isWorkingHours) {
        msg =
          'Please place orders prior to 4.30pm. Anything placed after this will not be dispatched the following day.';
      }
    } else {
      isWorkingHours = today.isBefore(moment('3:30pm', 'hh:mma'));
      if (!isWorkingHours) {
        msg =
          'Please place weekend orders by 3.30pm Sunday....!\nOrders placed after this will be dispatched on Tuesday';
      }

      return msg;
    }
  };

  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        {cart.length === 0 ? (
          <section className="empty-cart-block">
            <div className="main-block">
              <img src={EMPTY_CART} alt="EmptyCart" />
              <h3>Sorry, your cart is empty</h3>
              <p>Look like you have not added your products</p>
              <Button as={Link} to={MODULES.allProducts.url} variant="accent">
                add product into cart
              </Button>
            </div>
          </section>
        ) : (
          <section className="page-block my-cart-block">
            <div className="container-xl">
              <h3 className="page-title">My Cart</h3>
              <div className="row">
                <div className="col-lg-8">
                  <div className="table-responsive mb-2">
                    <Table className="primary-grid" responsive="lg">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Qty.</th>
                          <th className="text-right">Unit Price</th>
                          <th className="text-right">Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((item) => (
                          <tr key={item.id}>
                            <td>{item.product_name}</td>
                            <td>
                              <UpdatableField {...item} />
                            </td>
                            <td className="text-right" width={120}>
                              $ {item.product_price}
                            </td>
                            <td className="text-right" width={120}>
                              ${" "}
                              {item.total_price.toFixed(2)}
                            </td>
                            <td className="text-right">
                              <Button
                                className="btn btn-icon danger"
                                onClick={() => removeItem(item)}
                              >
                                <img src={BIN} alt="remove" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Button
                    as={Link}
                    to={MODULES.allProducts.url}
                    className="btn btn-stroke btn-iconic-text"
                  >
                    <img src={ADD_PRIMARY} alt="Add" />
                    <span>add more product</span>
                  </Button>

                  <div className="total-block">
                    {/* <div className="price-block">
                      <p>Sub Total</p>
                      <p>$ {getSubTotal()}</p>
                    </div> */}
                    <div className="price-block total">
                      <p>Total</p>
                      <p>$ {getTotal()}</p>
                    </div>
                    <div className="price-block alert alert-danger" role="alert">
                      <span className="warning">This is not an official invoice, invoice issued upon delivery</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="checkout-block">
                    <Formik
                      validateOnChange
                      validateOnBlur
                      validationSchema={checkoutSchema}
                      initialValues={{
                        deliveryDate: null,
                        comments: "",
                      }}
                      onSubmit={(values) => checkoutCustomer(values)}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <FormikForm onSubmit={handleSubmit}>
                          <Form.Group className="form-group">
                            <DatePicker
                              name="deliveryDate"
                              className="form-control datepicker"
                              selected={date}
                              value={values.deliveryDate}
                              onChange={(date) => {
                                if (!is_weekend(date)) {
                                  setFieldValue("deliveryDate", date);
                                  setDate(date);
                                }
                              }}
                              minDate={todayDate}
                              placeholderText="Choose Delivery Date"
                            />
                            <div className="errorMessage">
                              <ErrorMessage name="deliveryDate" />
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Control
                              as="textarea"
                              name="comments"
                              className="form-control"
                              placeholder="Enter Comments"
                              onChange={handleChange}
                              value={values.comments}
                            />
                          </Form.Group>
                          <Button
                            type="submit"
                            variant="accent"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner animation="border" variant="light" />
                            ) : (
                              <span>check out $ {getTotal()}</span>
                            )}
                          </Button>
                        </FormikForm>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>

      <Footer />

      {/* Ask Question Modal Start */}
      <Modal
        className="alert-modal"
        size="sm"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <Button className="btn btn-icon" onClick={handleClose}>
            <img src={MODAL_CLOSE} alt="close" />
          </Button>
          <img
            className="event-img"
            src={SUCCESSFULL_ORDER}
            alt="orderSuccess"
          />
          <h4 className="event-title">your order has been submitted</h4>
          <p className="event-description">
            {message()}
          </p>
        </Modal.Body>
      </Modal>
      {/* Ask Question Modal End */}
    </div>
  );
};

export default Cart;
