import React from "react";
import Banner from "../../components/Banner";
import Navbar from "../../components/NavBar";
import About from "../../components/About";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import ProductPricing from "../../components/ProductPricing";
import Product from "../../components/Products";
import Supplier from "../../components/Supplier";

export default function Home() {
  return (
    <div>
      <div className="wrapper">
        {/* Start Banner */}
        <Banner />
        {/* End Banner */}

        <div className="sticky-header">
          {/* Start NavBar */}
          <Navbar />
          {/* End NavBar */}
        </div>
        
        {/* Start About */}
        <About />
        {/* End About */}

        {/* Start Product Pricing */}
        <ProductPricing />
        {/* End Product Pricing */}

        {/* Start Supplier block */}
        <Supplier />
        {/* End Supplier block */}

        {/* Start Product */}
        <Product />
        {/* End Product */}

        {/* Start Contact */}
        <Contact />
        {/* End Contact */}
      </div>

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </div >
  )
}
