import React from "react";
import { Link } from "react-router-dom";
import { MODULES } from "../../utils/routesNames";
import { PRICING_LIST_PDF } from "../../assets/files/file";
// import {
//   FACEBOOK,
//   TWITTER,
//   INSTAGRAM,
//   YOUTUBE,
// } from "../../assets/images/images";

export default function Footer() {
  return (
    <div>
      {/* Start Footer Block */}
      <section className="footer-block">
        <div className="container-xl">
          <div className="row no-gutters">
            <div className="col-lg order-lg-1 order-2">
              <div className="linking-block">
                <ul className="secondary-nav">
                  <li>
                    <Link to={MODULES.home.url} title="Home">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to={MODULES.aboutUs.url} title="About">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={MODULES.allProducts.url} title="Product">
                      Product
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={PRICING_LIST_PDF}
                      title="Price List"
                      target="_blank"
                      download
                    >
                      Price List
                    </Link>
                  </li>
                  <li>
                    <Link to={MODULES.contactUs.url} title="Contact">
                      Contact
                    </Link>
                  </li>
                </ul>
                <div className="copyright-block">
                  <p className="copyright-text">
                    Copyright &copy;2020 ARZ Food Service. All rights reserved.
                  </p>
                  <span>
                    <Link
                      to={MODULES.termsConditions.url}
                      title="Term &amp; Condition"
                    >
                      Term &amp; Condition
                    </Link>
                    <Link to={MODULES.privacyPolicy.url} title="Privacy Policy">
                      Privacy Policy
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-auto order-lg-2 order-1">
              <div className="social-media-block">
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  title="facebook"
                >
                  <img src={FACEBOOK} alt="facebook" />
                </Link>
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  title="twitter"
                >
                  <img src={TWITTER} alt="twitter" />
                </Link>
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  title="instagram"
                >
                  <img src={INSTAGRAM} alt="instagram" />
                </Link>
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  title="youtube"
                >
                  <img src={YOUTUBE} alt="youtube" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* End Footer Block */}
    </div>
  );
}
