import React, { useState } from "react";
import { Category } from "../../modals/Category";
import { isValidImageUrl } from "../../utils/common";
import { NO_IMG } from "../../assets/images/images";

interface propsFunction {
  loadSubCategories: (id: number) => void;
}

const CategoryItem: React.FC<Category & propsFunction> = ({
  id,
  name,
  category_image,
  loadSubCategories,
}) => {
  const [image, setImage] = useState(category_image);

  return (
    <div onClick={() => loadSubCategories(id)} className="col-lg-2 col-sm-3">
      <div className="category-item">
        <img
          onError={() => setImage(NO_IMG)}
          src={isValidImageUrl(image) ? image : NO_IMG}
          alt={category_image}
        />
        <p className="category-item-footer">{name}</p>
      </div>
    </div>
  );
};

export default CategoryItem;
