import React from "react";
import Loader from "../Loader";
import ProductItem from "./productItem";

const ProductsList = ({ products, status }) => {

  return (
    <div>
      <div className="product-list-item-wrapper">
        {status ? (
          status
        ) : products.length !== 0 ? (
          products.map((product) => (
            <ProductItem key={product.id} {...product} />
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ProductsList;
