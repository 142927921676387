import React from "react";
import { Link } from "react-router-dom";
import { BANNER_LOGO } from "../../assets/images/images";
import { SCROLL_DOWN_ARROW } from "../../assets/images/svgImage";

export default function Banner() {
  function scrollToBlock(e) {
    e.preventDefault();
    document.documentElement.classList.add("page-loaded");
    setTimeout(function () {
      let bannerBlock = document.querySelector(".banner");
      if(bannerBlock) window.scrollTo(0, bannerBlock.clientHeight);
      document.documentElement.classList.remove("page-loaded");
    }, 10);
  }

  return (
    <div>
      {/* Start Banner */}
      <section className="banner">
        <div className="banner-content">
          <img
            src={BANNER_LOGO}
            alt="ARZ Food Service"
            width="430"
            className="banner-logo"
          />
          <h1 className="tag-line">45 Years of Trusted Services</h1>
        </div>
        <Link
          to="#"
          title="Scroll Down"
          onClick={scrollToBlock}
          className="scroll-down-btn"
        >
          <img src={SCROLL_DOWN_ARROW} alt="Scroll Down" />
        </Link>
      </section>
      {/* End Banner */}
    </div>
  );
}
