import React, { useState, ChangeEvent, useContext } from "react";
import { CartProducts } from "../../modals/Cart";
import Form from "react-bootstrap/Form";
import { INCREMENT, DECREMENT } from "../../assets/images/svgImage";
import Button from "react-bootstrap/Button";
import { addToCartMessages as msg } from "../../utils/constants";
import { UpdateCartService, ViewCartService } from "../../services/services";
import AppContext from "../../context/AppContext";

const UpdatableField: React.FC<CartProducts> = (item) => {
  const [value, setValue] = useState(item.quantity);
  const { setCart, tokenExpired } = useContext(AppContext);

  const isValid = (val: number) => val <= msg.MAX_QTY && val >= msg.MIN_QTY;

  const UpdateCart = (val: number) => {
    UpdateCartService(item.id, item.product_id, val)
      .then(() => {
        return ViewCartService();
      })
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (isValid(+e.target.value)) {
      setValue(+e.target.value);
      UpdateCart(+e.target.value);
    }
  };

  const handleIncrement = () => {
    if (isValid(value + 1)) {
      setValue((prevVal) => prevVal + 1);
      UpdateCart(value + 1);
    }
  };

  const handleDecrement = () => {
    if (isValid(value - 1)) {
      setValue((prevVal) => prevVal - 1);
      UpdateCart(value - 1);
    }
  };

  return (
    <div className="number-input">
      <Form.Group className="form-group">
        <Form.Control
          type="number"
          min="1"
          max="50"
          className="form-control"
          value={value}
          onChange={(e) => handleChange(e)}
        />
        <span className="action">
          <Button
            className="btn btn-icon"
            onClick={() => handleIncrement()}
            onDoubleClick={() => {}}
          >
            <img src={INCREMENT} alt="^" />
          </Button>
          <Button
            className="btn btn-icon"
            onClick={() => handleDecrement()}
            onDoubleClick={() => {}}
          >
            <img src={DECREMENT} alt="v" />
          </Button>
        </span>
      </Form.Group>
    </div>
  );
};

export default UpdatableField;
