import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, NavLink } from "react-router-dom";
// services
import AppContext from "../../context/AppContext";
import { MODULES } from "../../utils/routesNames";
import { PRICING_LIST_PDF } from "../../assets/files/file";
import { LOGO_PNG } from "../../assets/images/images";
import {
  CART_WHITE,
  MY_ORDER,
  SETTING,
  LOGOUT,
} from "../../assets/images/svgImage";
import { history } from "../../utils/appConfig";

const MainNav: React.FC = () => {
  const { auth, setAuth, cart, setCart } = useContext(AppContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setAuth(null);
    setCart([]);
  };

  return (
    <div>
      {/* Start NavBar */}
      <Navbar
        collapseOnSelect
        id="scrollTo"
        expand="lg"
        bg="primary"
        variant="dark"
      >
        <div className="container-xl">
          <Navbar.Brand
            as={Link}
            to={MODULES.home.url}
            title="ARZ Food Service"
          >
            <img src={LOGO_PNG} alt="ARZ Food Service" className="logo" />
          </Navbar.Brand>
          <div>
            <Button
              as={Link}
              to={MODULES.cart.url}
              variant="link"
              className="cart-btn d-lg-none"
            >
              <img src={CART_WHITE} alt="Cart" width="28" />{" "}
              {cart.length !== 0 ? <i>{cart.length}</i> : null}
            </Button>
            <Navbar.Toggle
              className="d-lg-none"
              aria-controls="responsive-navbar-nav"
            >
              <span></span>
              <span></span>
              <span></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavLink
                className="nav-link"
                to={MODULES.aboutUs.url}
                title="About"
              >
                About
              </NavLink>
              <NavLink
                className="nav-link"
                to={MODULES.allProducts.url}
                title="Products"
                isActive={() =>
                  history.location.pathname.startsWith("/product")
                }
              >
                Products
              </NavLink>
              <NavLink
                className="nav-link"
                to={PRICING_LIST_PDF}
                title="Pricing List"
                target="_blank"
                download
              >
                Pricing List
              </NavLink>
              <NavLink
                className="nav-link"
                to={MODULES.contactUs.url}
                title="Contact"
              >
                Contact
              </NavLink>
            </Nav>
            <Nav>
              <Button
                as={Link}
                to={MODULES.cart.url}
                variant="link"
                className="cart-btn d-none d-lg-block"
              >
                <img src={CART_WHITE} alt="Cart" width="28" />
                {cart.length ? <i>{cart.length}</i> : null}
              </Button>

              {auth ? (
                <>
                  {/* Start User Dropdown */}
                  <DropdownButton
                    className="user-dropdown"
                    alignRight
                    title={auth.name}
                    id="dropdown-menu-align-right"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      as={Link}
                      to={MODULES.orderHistory.url}
                    >
                      <i>
                        <img src={MY_ORDER} alt="My Orders" />
                      </i>
                      My Orders
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      as={Link}
                      to={MODULES.accountSettings.url}
                    >
                      <i>
                        <img src={SETTING} alt="Account Setting" />
                      </i>
                      Account Setting
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={handleLogout}>
                      <i>
                        <img src={LOGOUT} alt="Logout" />
                      </i>
                      Logout
                    </Dropdown.Item>
                  </DropdownButton>
                  {/* End User Dropdown */}
                </>
              ) : (
                <>
                  {/* Start Login and Signup Button */}
                  <Button as={Link} to={MODULES.login.url} variant="light">
                    Login
                  </Button>
                  <Button
                    as={Link}
                    to={MODULES.signUp.url}
                    variant="outline-light"
                    href={MODULES.signUp.url}
                  >
                    Register
                  </Button>
                  {/* End Login and Signup Button */}
                </>
              )}
              <Button variant="outline-light" className="large_device">
                <a target="_blank" href={MODULES.applyCreditAccount.url}>
                  Apply for Credit A/C
                </a>
              </Button>
            </Nav>
          </Navbar.Collapse>
        </div>
        <Button variant="outline-light" className="small_device">
          <a target="_blank" href={MODULES.applyCreditAccount.url}>
            Apply for Credit A/C
          </a>
        </Button>
      </Navbar>
      {/* End NavBar */}
    </div>
  );
};

export default MainNav;
