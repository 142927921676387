export const MODULES = {
  landingPage: { name: "Landing Page", url: "/landing" },
  login: { name: "Login", url: "/login" },
  signUp: { name: "Sign Up", url: "/signup" },
  home: { name: "Home", url: "/home" },
  generalInquiry: { name: "General Inquiry", url: "/generalInquiry" },
  accountSettings: { name: "Account Settings", url: "/accountSettings" },
  products: { name: "Product", url: "/product" },
  allProducts: { name: "Product", url: "/product/All" },
  productsList: { name: "Products", url: "/product/All/category" },
  savedProducts: { name: "Product", url: "/product/saved" },
  historyProducts: { name: "Product", url: "/product/history" },
  productDetails: { name: "Product Details", url: "/productDetails" },
  orderHistory: { name: "Order History", url: "/orderHistory" },
  orderDetails: { name: "Order Details", url: "/orderHistory/details" },
  cart: { name: "Cart", url: "/cart" },
  aboutUs: { name: "About Us", url: "/about" },
  contactUs: { name: "Contact Us", url: "/contact" },
  termsConditions: { name: "Terms & Conditions", url: "/terms" },
  privacyPolicy: { name: "Privacy Policy", url: "/privacy" },
  supplierList: { name: "Supplier List", url: "/suppliers" },
  applyCreditAccount: { name: "Apply for Credit A/C", url: "https://arzfoodservice.applyeasy.com.au/credit/introduction" }
};
