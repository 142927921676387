import React from "react";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { MODULES } from "../../utils/routesNames";
import {
  DIROSSI_NEW,
  INGHAMS_NEW,
  SANREMO_NEW,
  PRIMO,
  NESTLE,
  KRAFT_FOODS,
  SHARWOODS,
  GOLDEN_CIRCLE,
  CASA_DELLA_PASTA,
} from "../../assets/images/images";

import ScrollAnimation from "react-animate-on-scroll";

export default function Supplier() {
  return (
    <div>
      {/* Start About Block */}
      <section className="content-block supplier-block">
        <div className="container-xl">
          <div className="row">
            <div className="col-xl">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <h2 className="content-heading ">Our Suppliers</h2>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={300}
              >
                <p>
                  So that our customers will always have access to the highest
                  quality products, ARZ Food Service is focussed on building
                  mutually beneficial partnerships with our suppliers so that
                  both businesses can grow alongside each other. We recognise
                  the critical role each supplier plays in our business and
                  those of our customers.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={500}
              >
                <Button
                  as={Link}
                  to={MODULES.supplierList.url}
                  variant="primary"
                  title="View more"
                >
                  View more
                </Button>
              </ScrollAnimation>
            </div>
            <div className="col-xl-auto">
              <ul className="supplier-list d-none d-md-block">
                <li>
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Primo"
                    >
                      <img src={PRIMO} alt="Primo" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={700}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Nestle"
                    >
                      <img src={NESTLE} alt="Nestle" />
                    </a>
                  </ScrollAnimation>
                </li>
                <li>
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={800}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Kraft Foods"
                    >
                      <img src={KRAFT_FOODS} alt="Kraft Foods" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={900}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Sharwoods"
                    >
                      <img src={SHARWOODS} alt="Sharwoods" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1000}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Inghams"
                    >
                      <img src={INGHAMS_NEW} alt="Inghams" />
                    </a>
                  </ScrollAnimation>
                </li>
                <li>
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1100}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Golden Circle"
                    >
                      <img src={GOLDEN_CIRCLE} alt="Golden Circle" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1200}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Casa Della Pasta"
                    >
                      <img src={CASA_DELLA_PASTA} alt="Casa Della Pasta" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1300}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Di Rossi"
                    >
                      <img src={DIROSSI_NEW} alt="Di Rossi" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1400}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="SanRemo"
                    >
                      <img src={SANREMO_NEW} alt="SanRemo" />
                    </a>
                  </ScrollAnimation>
                </li>
              </ul>

              <ul className="supplier-list d-md-none">
                <li>
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Primo"
                    >
                      <img src={PRIMO} alt="Primo" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={700}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Nestle"
                    >
                      <img src={NESTLE} alt="Nestle" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={800}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Kraft Foods"
                    >
                      <img src={KRAFT_FOODS} alt="Kraft Foods" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={900}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Sharwood's"
                    >
                      <img src={SHARWOODS} alt="Sharwood's" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1000}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Inghams"
                    >
                      <img src={INGHAMS_NEW} alt="Inghams" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1100}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Golden Circle"
                    >
                      <img src={GOLDEN_CIRCLE} alt="Golden Circle" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1200}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Casa Della Pasta"
                    >
                      <img src={CASA_DELLA_PASTA} alt="Casa Della Pasta" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1300}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Di Rossio"
                    >
                      <img src={DIROSSI_NEW} alt="Di Rossio" />
                    </a>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1400}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="SanRemo"
                    >
                      <img src={SANREMO_NEW} alt="SanRemo" />
                    </a>
                  </ScrollAnimation>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* End About Block */}
    </div>
  );
}
