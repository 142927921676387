// const URL = "http://arzfoodservice.web1.anasource.com/";
export const ABOUT_BG_LEFT_TOP = require("./about-bg-left-top.png");
export const ABOUT_BG_RIGHT_BOTTOM = require("./about-bg-right-bottom.png");
export const ABOUT_US_BANNER = require("./aboutus-banner.jpg");
export const ALLIED_MILLS = require("./AlliedMills.png");
export const ALLIED_MILLS_NEW = require("./allied-mills.jpg");
export const ALL_LOGOS = require("./all-logos.png");
export const ANTIPASTRO = require("./antipastro.jpg");
export const ARZ_FOOD_SERVICE = require("./ARZ-food-service.jpg");
export const BACON_DICED = require("./bacon-diced.jpg");
export const BACON_STREAKY_PRODUCT = require("./bacon-streaky-product.jpg");
export const BAKEN_SLICE_D6 = require("./baken-sliced-6.jpg");
export const BANNER_BG = require("./banner-bg.jpg");
export const BANNER_LOGO = require("./banner-logo.png");
export const BANNER_ABOUT_US = require("./banner-about-us.png");
export const BLACK_SWAN = require("./black-swan.png");
export const BURGER = require("./burger.jpg");
export const CALENDAR_LIGHT = require("./calendar-light.svg");
export const CASA_DELLA_PASTA = require("./casa-della-pasta.png");
export const CHEFMASTER = require("./chefmaster.png");
export const CHEVRON_DOWN_WHITE = require("./chevron-down-white.svg");
export const CONTACT_PRIMARY = require("./contact-primary.svg");
export const CURENTED_MEAT = require("./curented-meat.jpg");
export const DAIRY_PRODUCT = require("./dairy-product.jpg");
export const DESSERT = require("./dessert.jpg");
export const DIROSSI = require("./Di-Rossi.png");
export const DIROSSI_NEW = require("./Di-Rossi-new.jpg");
export const DON = require("./DON.png");
export const EDGELL = require("./Edgell.png");
export const EDGELL_NEW = require("./Edgell-new.png");
export const EXPLORE_FOOD = require("./explore-food.jpg");
export const FOOTER_BG_LEFT = require("./footer-bg-left.png");
export const FOOTER_BG_RIGHT = require("./footer-bg-right.png");
export const FRANK_FRUIT_HOTDOG = require("./frank-fruit-hotdog.jpg");
export const GOLDEN_CIRCLE = require("./golden-circle.png");
export const HAM = require("./ham.jpg");
export const HEINZ = require("./Heinz.png");
export const INGHAMS = require("./Inghams.png");
export const INGHAMS_NEW = require("./Inghams-new.jpg");
export const KRAFT_FOODS = require("./kraft-foods.png");
export const LOCATION_PRIMARY = require("./location-primary.svg");
export const LOGIN_BG = require("./login-bg.jpg");
export const LOGIN_LOGO = require("./login-logo.png");
export const LOGO_PNG = require("./logo.png");
export const LOGO_SVG = require("./logo.svg");
export const MARATHON = require("./Marathon.png");
export const MARATHON_NEW = require("./Marathon-new.jpg");
export const MAGGI = require("./maggi.png");
export const MASTERFOOD = require("./MasterFood.png");
export const MASTERFOOD_NEW = require("./MasterFood-new.png");
export const MEATS = require("./meats.jpg");
export const MODAL_CLOSE = require("./modal-close.png");
export const NESTLE = require("./nestle.png");
export const NO_IMG = require("./no-img.png");
export const NON_VEG = require("./non-veg.jpeg");
export const OIL = require("./oil.jpg");
export const PAST_NUDLESIL = require("./past-nudles.jpg");
export const PL_BACON_BUDGET_SLICE = require("./PL-bacon-budget-slice.jpg");
export const PL_BACON_RING = require("./PL-bacon-ring.jpg");
export const PL_BACON_SLICES = require("./PL-bacon-slices.jpg");
export const PL_BACON_STREAKY = require("./PL-bacon-streaky.jpg");
export const PRIMO = require("./primo.jpg");
export const PRODUCT_BLOCK_BANNER = require("./product-block-banner.jpg");
export const PRODUCT_PRICING_BANNER = require("./product-pricing-banner.jpg");
export const PURA = require("./pura.jpg");
export const RICE = require("./rice.jpg");
export const RIVIANA = require("./riviana.png");
export const SALAMI = require("./salami.jpg");
export const SANREMO = require("./SanRemo.png");
export const SANREMO_NEW = require("./SanRemo-new.jpeg");
export const SHARWOODS = require("./sharwoods.jpg");
export const SMALLGOODS = require("./small-goods.jpg");
export const SUPPLIER_RIGHT_BOTTOM = require("./supplier-right-bottom.png");
export const TATURA = require("./tatura.png");
export const TIP_TOP = require("./tip-top.jpeg");
export const VEG = require("./veg.jpg");