import React, { useState, useEffect, useContext } from "react";
import { OrderHistoryDetails } from "../../modals/Order";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import {
  HistoryOrderFullDetailService,
  DuplicateOrder,
  ViewCartService,
} from "../../services/services";
import { MODULES } from "../../utils/routesNames";
import {
  generalMessages as MSG,
  orderDetailMessages as orderDetailMSG,
} from "../../utils/constants";
import AppContext from "../../context/AppContext";
import { BACK_LINK } from "../../assets/images/svgImage";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const OrderDetails = ({
  match: {
    params: { orderID },
  },
}) => {
  const { setCart, tokenExpired } = useContext(AppContext);
  const [orderDetails, setOrderDetails] = useState<OrderHistoryDetails | null>(
    null
  );

  useEffect(() => {
    const getOrderDetails = () => {
      HistoryOrderFullDetailService(orderID)
        .then((result) => {
          if (result.data.flag) {
            setOrderDetails(result.data.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            tokenExpired();
          }
        });
    };
    if (orderID) {
      getOrderDetails();
    }
  }, []); // eslint-disable-line

  const duplicateOrder = () => {
    DuplicateOrder(orderID)
      .then((result) => {
        if (result.data.flag) {
          toast.success(orderDetailMSG.SUCCESS);
        } else {
          toast.error(MSG.ERROR_MSG);
        }
        return ViewCartService();
      })
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>

        <section className="page-block">
          <div className="container-xl">
            <Link
              to={MODULES.orderHistory.url}
              className="page-back-link"
              title="back to My orders"
            >
              <img src={BACK_LINK} alt="back" /> back to My orders
            </Link>
            {orderDetails ? (
              <div className="my-order-wrapper">
                <div className="my-order-card">
                  <div>
                    <div className="labeled-data">
                      <p>Order Date</p>
                      <span>{orderDetails?.order_date}</span>
                    </div>
                    <div className="labeled-data">
                      <p>Order Amount</p>
                      <span>${orderDetails?.sub_total}</span>
                    </div>
                    <Button
                      onClick={() => duplicateOrder()}
                      className="btn btn-strock"
                    >
                      Duplicate Order
                    </Button>
                  </div>
                  <p className="orderID">#{orderDetails?.id}</p>
                </div>

                <div className="table-responsive">
                  <Table className="primary-grid no-control" responsive="lg">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th className="text-center">Qty.</th>
                        <th className="text-right">Unit Price</th>
                        <th className="text-right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails?.products?.map((product) => (
                        <tr key={product.product_id}>
                          <td>{product.product_name}</td>
                          <td className="text-center">
                            {product.product_quantity}
                          </td>
                          <td className="text-right">
                            ${product.product_price}
                          </td>
                          <td className="text-right">
                            ${product.product_quantity * product.product_price}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="total-block">
                  <div className="price-block total">
                    <p>Total</p>
                    <p>${orderDetails?.sub_total}</p>
                  </div>

                  {/* Grand Total includes shipping charge uncomment following to display */}
                  {/* <div className="price-block total">
                    <p>Total</p>
                    <p>${orderDetails?.grand_total}</p>
                  </div> */}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default OrderDetails;
