/* eslint-disable func-names */
import axios, { InternalAxiosRequestConfig } from "axios";

// Set config defaults when creating the instance
const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_BASE_URL
      : process.env.REACT_APP_API_BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(async(config: InternalAxiosRequestConfig) => {
  const accessToken = await localStorage.getItem("token");
  if (accessToken) {
    config.headers = config.headers ?? {};
    // Now config.headers can be safely used
    config.headers.Authorization = `Bearer ${accessToken}`

    return config;
  }
  return config;
});

export default axiosInstance;
