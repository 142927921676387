import React, { useState, useContext } from "react";
import Loader from "../Loader";
import SubstituteProductItem from "./SubstituteProduct";

interface ParentProps {
  closeModal: Function;
  products: any[];
  status: any;
}

const SubstituteProductList: React.FC<ParentProps> = ({
  products,
  closeModal,
  status
}) => {
  return (
    <div className="selected-question-wrapper">
      {status ? (
        status
      ) : products.length !== 0 ? (
        products.map((product) => <SubstituteProductItem key={product.id} {...product} />)
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SubstituteProductList;
