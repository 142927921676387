import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/esm/Nav";

import { GetSubstituteProducts } from "../../services/services";
import AppContext from "../../context/AppContext";
import { history } from "../../utils/appConfig";
import { MODULES } from "../../utils/routesNames";
import SubstituteProductList from "./SubstituteProductList";
import { generalMessages } from "../../utils/constants";
import { MODAL_CLOSE } from "../../assets/images/images";
import { Product } from "../../modals/Product";

interface SubstituteProductsInterface {
  id: number;
}
interface ResponseFormate {
  header: string;
  message: string;
  product_data: Product[];
}

const SubstituteProducts: React.FC<SubstituteProductsInterface> = ({ id }) => {
  const { auth, tokenExpired } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [substituteProducts, setSubstituteProducts] =
    useState<ResponseFormate | null>(null);

  const getSubstituteProducts = (productId) => {
    setStatus(null);
    GetSubstituteProducts(productId)
      .then((result) => {
        if (result.data.flag) {
          setSubstituteProducts(result?.data?.data);
          if (result?.data?.data?.product_data?.length) {
            setStatus(null);
          } else {
            setStatus(generalMessages.PRODUCT_NOT_FOUND);
          }
        } else {
          setStatus(generalMessages.ERROR_MSG);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setStatus(null);
    setSubstituteProducts(null);
  };

  const handleModalOpen = () => {
    if (auth) {
      setModalShow(true);
      getSubstituteProducts(id);
    } else {
      history.push(MODULES.login.url);
    }
  };

  return (
    <>
      <Nav.Link
        style={{
          paddingLeft: "10px",
          display: "inline",
          textDecoration: "underline",
        }}
        className="que-modal-link"
        onClick={() => handleModalOpen()}
        title="Click here for substitutable Products"
      >
        Click here for substitutable Products
      </Nav.Link>
      <Modal
        className="primary-modal"
        size="sm"
        show={modalShow}
        onHide={handleModalClose}
        centered
      >
        <Modal.Header>
          <Modal.Title>{substituteProducts?.header}</Modal.Title>
          <Button className="btn btn-icon" onClick={handleModalClose}>
            <img src={MODAL_CLOSE} alt="close" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{substituteProducts?.message}</p>
          <SubstituteProductList
            status={status}
            products={substituteProducts?.product_data ?? []}
            closeModal={handleModalClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="btn btn-link-danger"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubstituteProducts;
