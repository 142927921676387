import { productImageDomain, regEx } from "./constants";
import { Category } from "../modals/Category";

export function setLocalStorage(key, value) {
  let val;
  if (typeof value === "object") {
    val = JSON.stringify(value);
  } else {
    val = value;
  }
  localStorage.setItem(key, val);
}

//Un Masking Mobile Number before saving
export function unMaskMobileNumber(mobileNumber: string) {
  return mobileNumber.replace(/ /g, "");
}

export function productImageUrl(imageName: string) {
  return productImageDomain.replace("image_name", imageName);
}

export const isValidImageUrl = (url: string): boolean => {
  return regEx.imageUrl.test(url);
};

export function getPathTo(item: string, root: Category[]) {
  let index = {};
  function buildIndex(rootName, sub_categories) {
    for (var i in sub_categories) {
      index[sub_categories[i].name] = rootName;
      buildIndex(sub_categories[i].name, sub_categories[i].sub_categories);
    }
  }
  buildIndex("All Products", root);
  function getPath(leaf) {
    return index[leaf] ? getPath(index[leaf]).concat([leaf]) : [leaf];
  }
  return getPath(item);
}

export function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}
